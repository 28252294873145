/**
 * A sharable identity function. Returns the input as-is with no transformation applied.
 */
export function identity(value) {
    return value;
}
/**
 * A sharable noop function. As the name suggests, does nothing
 */
export function noop() {
    // noop
}
