export default {
    rpc: {},
    types: {
        IdentityFields: {
            _set: {
                _bitLength: 64,
                // Mapped here to 32 bits, in Rust these are 64-bit values
                Display: 1,
                Legal: 2,
                Web: 4,
                Riot: 8,
                Email: 16,
                PgpFingerprint: 32,
                Image: 64,
                Twitter: 128
            }
        },
        IdentityInfoAdditional: '(Data, Data)',
        IdentityInfoTo198: {
            additional: 'Vec<IdentityInfoAdditional>',
            display: 'Data',
            legal: 'Data',
            web: 'Data',
            riot: 'Data',
            email: 'Data',
            pgpFingerprint: 'Option<H160>',
            image: 'Data'
        },
        IdentityInfo: {
            _fallback: 'IdentityInfoTo198',
            additional: 'Vec<IdentityInfoAdditional>',
            display: 'Data',
            legal: 'Data',
            web: 'Data',
            riot: 'Data',
            email: 'Data',
            pgpFingerprint: 'Option<H160>',
            image: 'Data',
            twitter: 'Data'
        },
        IdentityJudgement: {
            _enum: {
                Unknown: 'Null',
                FeePaid: 'Balance',
                Reasonable: 'Null',
                KnownGood: 'Null',
                OutOfDate: 'Null',
                LowQuality: 'Null',
                Erroneous: 'Null'
            }
        },
        RegistrationJudgement: '(RegistrarIndex, IdentityJudgement)',
        RegistrationTo198: {
            judgements: 'Vec<RegistrationJudgement>',
            deposit: 'Balance',
            info: 'IdentityInfoTo198'
        },
        Registration: {
            _fallback: 'RegistrationTo198',
            judgements: 'Vec<RegistrationJudgement>',
            deposit: 'Balance',
            info: 'IdentityInfo'
        },
        RegistrarIndex: 'u32',
        RegistrarInfo: {
            account: 'AccountId',
            fee: 'Balance',
            fields: 'IdentityFields'
        }
    }
};
