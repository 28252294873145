import { versioned as centrifugeChain } from './centrifuge-chain.js';
import { versioned as kusama } from './kusama.js';
import { versioned as node } from './node.js';
import { versioned as nodeTemplate } from './node-template.js';
import { versioned as polkadot } from './polkadot.js';
import { versioned as rococo } from './rococo.js';
import { versioned as shell } from './shell.js';
import { versioned as statemine } from './statemine.js';
import { versioned as statemint } from './statemint.js';
import { versioned as westend } from './westend.js';
import { versioned as westmint } from './westmint.js';
export const typesSpec = {
    'centrifuge-chain': centrifugeChain,
    kusama,
    node,
    'node-template': nodeTemplate,
    polkadot,
    rococo,
    shell,
    statemine,
    statemint,
    westend,
    westmint
};
