export const versioned = [
    {
        minmax: [0, undefined],
        types: {
            // nothing, API tracks master
            // (v2 weights are not yet the default)
            Weight: 'WeightV2'
        }
    }
];
