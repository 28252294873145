export default {
    rpc: {},
    types: {
        WeightToFeeCoefficient: {
            coeffInteger: 'Balance',
            coeffFrac: 'Perbill',
            negative: 'bool',
            degree: 'u8'
        }
    }
};
