import { combineLatest, map, of, switchMap } from 'rxjs';
import { memo } from '../util/index.js';
function _membersPrev(api, accountIds) {
    return combineLatest([
        of(accountIds),
        api.query.society.payouts.multi(accountIds),
        api.query.society['strikes'].multi(accountIds),
        api.query.society.defenderVotes.multi(accountIds),
        api.query.society.suspendedMembers.multi(accountIds),
        api.query.society['vouching'].multi(accountIds)
    ]).pipe(map(([accountIds, payouts, strikes, defenderVotes, suspended, vouching]) => accountIds.map((accountId, index) => ({
        accountId,
        isDefenderVoter: defenderVotes[index].isSome,
        isSuspended: suspended[index].isTrue,
        payouts: payouts[index],
        strikes: strikes[index],
        vote: defenderVotes[index].unwrapOr(undefined),
        vouching: vouching[index].unwrapOr(undefined)
    }))));
}
function _membersCurr(api, accountIds) {
    return combineLatest([
        of(accountIds),
        api.query.society.members.multi(accountIds),
        api.query.society.payouts.multi(accountIds),
        api.query.society.defenderVotes.multi(accountIds),
        api.query.society.suspendedMembers.multi(accountIds)
    ]).pipe(map(([accountIds, members, payouts, defenderVotes, suspendedMembers]) => accountIds
        .map((accountId, index) => members[index].isSome
        ? {
            accountId,
            isDefenderVoter: defenderVotes[index].isSome
                ? defenderVotes[index].unwrap().approve.isTrue
                : false,
            isSuspended: suspendedMembers[index].isSome,
            member: members[index].unwrap(),
            payouts: payouts[index].payouts
        }
        : null)
        .filter((m) => !!m)
        .map(({ accountId, isDefenderVoter, isSuspended, member, payouts }) => ({
        accountId,
        isDefenderVoter,
        isSuspended,
        payouts,
        strikes: member.strikes,
        vouching: member.vouching.unwrapOr(undefined)
    }))));
}
export function _members(instanceId, api) {
    return memo(instanceId, (accountIds) => api.query.society.members.creator.meta.type.isMap
        ? _membersCurr(api, accountIds)
        : _membersPrev(api, accountIds));
}
/**
 * @description Get the member info for a society
 */
export function members(instanceId, api) {
    return memo(instanceId, () => api.query.society.members.creator.meta.type.isMap
        ? api.query.society.members.keys().pipe(switchMap((keys) => api.derive.society._members(keys.map(({ args: [accountId] }) => accountId))))
        : api.query.society.members().pipe(switchMap((members) => api.derive.society._members(members))));
}
