import { U8aFixed } from '@polkadot/types-codec';
import { hexToU8a, isHex, isString, isU8a, u8aToU8a } from '@polkadot/util';
import { decodeAddress, encodeAddress } from '@polkadot/util-crypto';
/** @internal */
function decodeAccountId(value) {
    if (isU8a(value) || Array.isArray(value)) {
        return u8aToU8a(value);
    }
    else if (!value) {
        return new Uint8Array();
    }
    else if (isHex(value)) {
        return hexToU8a(value);
    }
    else if (isString(value)) {
        return decodeAddress(value.toString());
    }
    throw new Error(`Unknown type passed to AccountId constructor, found typeof ${typeof value}`);
}
class BaseAccountId extends U8aFixed {
    constructor(registry, allowedBits = 256 | 264, value) {
        const decoded = decodeAccountId(value);
        const decodedBits = decoded.length * 8;
        // Part of stream containing >= 32 bytes or a all empty (defaults)
        if (decodedBits < allowedBits && decoded.some((b) => b)) {
            throw new Error(`Invalid AccountId provided, expected ${allowedBits >> 3} bytes, found ${decoded.length}`);
        }
        super(registry, decoded, allowedBits);
    }
    /**
     * @description Compares the value of the input to see if there is a match
     */
    eq(other) {
        return super.eq(decodeAccountId(other));
    }
    /**
     * @description Converts the Object to to a human-friendly JSON, with additional fields, expansion and formatting of information
     */
    toHuman() {
        return this.toJSON();
    }
    /**
     * @description Converts the Object to JSON, typically used for RPC transfers
     */
    toJSON() {
        return this.toString();
    }
    /**
     * @description Converts the value in a best-fit primitive form
     */
    toPrimitive() {
        return this.toJSON();
    }
    /**
     * @description Returns the string representation of the value
     */
    toString() {
        return encodeAddress(this, this.registry.chainSS58);
    }
    /**
     * @description Returns the base runtime type name for this instance
     */
    toRawType() {
        return 'AccountId';
    }
}
/**
 * @name GenericAccountId
 * @description
 * A wrapper around an AccountId/PublicKey representation. Since we are dealing with
 * underlying PublicKeys (32 bytes in length), we extend from U8aFixed which is
 * just a Uint8Array wrapper with a fixed length.
 */
export class GenericAccountId extends BaseAccountId {
    constructor(registry, value) {
        super(registry, 256, value);
    }
}
export class GenericAccountId33 extends BaseAccountId {
    constructor(registry, value) {
        super(registry, 264, value);
    }
}
