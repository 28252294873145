import { isFunction, noop } from '@polkadot/util';
export class Combinator {
    __internal__allHasFired = false;
    __internal__callback;
    __internal__fired = [];
    __internal__fns = [];
    __internal__isActive = true;
    __internal__results = [];
    __internal__subscriptions = [];
    constructor(fns, callback) {
        this.__internal__callback = callback;
        // eslint-disable-next-line @typescript-eslint/require-await
        this.__internal__subscriptions = fns.map(async (input, index) => {
            const [fn, ...args] = Array.isArray(input)
                ? input
                : [input];
            this.__internal__fired.push(false);
            this.__internal__fns.push(fn);
            // Not quite 100% how to have a variable number at the front here
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/ban-types
            return fn(...args, this._createCallback(index));
        });
    }
    _allHasFired() {
        this.__internal__allHasFired ||= this.__internal__fired.filter((hasFired) => !hasFired).length === 0;
        return this.__internal__allHasFired;
    }
    _createCallback(index) {
        return (value) => {
            this.__internal__fired[index] = true;
            this.__internal__results[index] = value;
            this._triggerUpdate();
        };
    }
    _triggerUpdate() {
        if (!this.__internal__isActive || !isFunction(this.__internal__callback) || !this._allHasFired()) {
            return;
        }
        try {
            Promise
                .resolve(this.__internal__callback(this.__internal__results))
                .catch(noop);
        }
        catch {
            // swallow, we don't want the handler to trip us up
        }
    }
    unsubscribe() {
        if (!this.__internal__isActive) {
            return;
        }
        this.__internal__isActive = false;
        this.__internal__subscriptions.map(async (subscription) => {
            try {
                const unsubscribe = await subscription;
                if (isFunction(unsubscribe)) {
                    unsubscribe();
                }
            }
            catch {
                // ignore
            }
        });
    }
}
