import { compactAddLength, compactStripLength, compactToU8a, isHex, isU8a, u8aToU8a } from '@polkadot/util';
import { Raw } from '../native/Raw.js';
import { typeToConstructor } from '../utils/index.js';
import { Bytes } from './Bytes.js';
function decodeRaw(registry, typeName, value) {
    const Type = typeToConstructor(registry, typeName);
    if (isU8a(value) || isHex(value)) {
        try {
            const [, u8a] = isHex(value)
                ? [0, u8aToU8a(value)]
                : (value instanceof Raw)
                    ? [0, value.subarray()]
                    : compactStripLength(value);
            return [Type, new Type(registry, u8a), value];
        }
        catch {
            return [Type, null, value];
        }
    }
    const instance = new Type(registry, value);
    return [Type, instance, compactAddLength(instance.toU8a())];
}
export class WrapperKeepOpaque extends Bytes {
    __internal__Type;
    __internal__decoded;
    __internal__opaqueName;
    constructor(registry, typeName, value, { opaqueName = 'WrapperKeepOpaque' } = {}) {
        const [Type, decoded, u8a] = decodeRaw(registry, typeName, value);
        super(registry, u8a);
        this.__internal__Type = Type;
        this.__internal__decoded = decoded;
        this.__internal__opaqueName = opaqueName;
    }
    static with(Type) {
        return class extends WrapperKeepOpaque {
            constructor(registry, value) {
                super(registry, Type, value);
            }
        };
    }
    /**
     * @description Checks if the wrapper is decodable
     */
    get isDecoded() {
        return !!this.__internal__decoded;
    }
    /**
     * @description Returns a breakdown of the hex encoding for this Codec
     */
    inspect() {
        return this.__internal__decoded
            ? {
                inner: [this.__internal__decoded.inspect()],
                outer: [compactToU8a(this.length)]
            }
            : {
                outer: [compactToU8a(this.length), this.toU8a(true)]
            };
    }
    /**
     * @description Converts the Object to to a human-friendly JSON, with additional fields, expansion and formatting of information
     */
    toHuman(isExtended) {
        return this.__internal__decoded
            ? this.__internal__decoded.toHuman(isExtended)
            : super.toHuman();
    }
    /**
     * @description Converts the value in a best-fit primitive form
     */
    toPrimitive() {
        return this.__internal__decoded
            ? this.__internal__decoded.toPrimitive()
            : super.toPrimitive();
    }
    /**
     * @description Returns the base runtime type name for this instance
     */
    toRawType() {
        return `${this.__internal__opaqueName}<${this.registry.getClassName(this.__internal__Type) || (this.__internal__decoded ? this.__internal__decoded.toRawType() : new this.__internal__Type(this.registry).toRawType())}>`;
    }
    /**
     * @description Converts the Object to to a string (either decoded or bytes)
     */
    toString() {
        return this.__internal__decoded
            ? this.__internal__decoded.toString()
            : super.toString();
    }
    /**
     * @description Returns the decoded that the WrapperKeepOpaque represents (if available), throws if non-decodable
     */
    unwrap() {
        if (!this.__internal__decoded) {
            throw new Error(`${this.__internal__opaqueName}: unwrapping an undecodable value`);
        }
        return this.__internal__decoded;
    }
}
