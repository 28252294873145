import { extractAuthor } from './util.js';
export function createHeaderExtended(registry, header, validators, author) {
    // an instance of the base extrinsic for us to extend
    const HeaderBase = registry.createClass('Header');
    class Implementation extends HeaderBase {
        __internal__author;
        constructor(registry, header, validators, author) {
            super(registry, header);
            this.__internal__author = author || extractAuthor(this.digest, validators || []);
            this.createdAtHash = header?.createdAtHash;
        }
        /**
         * @description Convenience method, returns the author for the block
         */
        get author() {
            return this.__internal__author;
        }
    }
    return new Implementation(registry, header, validators, author);
}
