/**
 * @name arrayChunk
 * @summary Split T[] into T[][] based on the defind size
 * @description
 * Returns a set ao arrays based on the chunksize
 * @example
 * <BR>
 *
 * ```javascript
 * import { arrayChunk } from '@polkadot/util';
 *
 * arrayChunk([1, 2, 3, 4, 5]); // [[1, 2], [3, 4], [5]]
 * ```
 */
export function arrayChunk(array, chunkSize) {
    const outputSize = Math.ceil(array.length / chunkSize);
    // shortcut for the single-split case
    if (outputSize === 1) {
        return [array];
    }
    const output = Array(outputSize);
    for (let i = 0; i < outputSize; i++) {
        const offset = i * chunkSize;
        output[i] = array.slice(offset, offset + chunkSize);
    }
    return output;
}
