import { getRandomValues } from '@polkadot/x-randomvalues';
const DEFAULT_CRYPTO = { getRandomValues };
const DEFAULT_SELF = { crypto: DEFAULT_CRYPTO };
/**
 * @name Wbg
 * @description
 * This defines the internal interfaces that wasm-bindgen used to communicate
 * with the host layer. None of these functions are available to the user, rather
 * they are called internally from the WASM code itself.
 *
 * The interfaces here are exposed in the imports on the created WASM interfaces.
 *
 * Internally the implementation does a thin layer into the supplied bridge.
 */
export class Wbg {
    __internal__bridge;
    constructor(bridge) {
        this.__internal__bridge = bridge;
    }
    /** @internal */
    abort = () => {
        throw new Error('abort');
    };
    /** @internal */
    __wbindgen_is_undefined = (idx) => {
        return this.__internal__bridge.getObject(idx) === undefined;
    };
    /** @internal */
    __wbindgen_throw = (ptr, len) => {
        throw new Error(this.__internal__bridge.getString(ptr, len));
    };
    /** @internal */
    __wbg_self_1b7a39e3a92c949c = () => {
        return this.__internal__bridge.addObject(DEFAULT_SELF);
    };
    /** @internal */
    __wbg_require_604837428532a733 = (ptr, len) => {
        throw new Error(`Unable to require ${this.__internal__bridge.getString(ptr, len)}`);
    };
    /** @internal */
    __wbg_crypto_968f1772287e2df0 = (_idx) => {
        return this.__internal__bridge.addObject(DEFAULT_CRYPTO);
    };
    /** @internal */
    __wbg_getRandomValues_a3d34b4fee3c2869 = (_idx) => {
        return this.__internal__bridge.addObject(DEFAULT_CRYPTO.getRandomValues);
    };
    /** @internal */
    __wbg_getRandomValues_f5e14ab7ac8e995d = (_arg0, ptr, len) => {
        DEFAULT_CRYPTO.getRandomValues(this.__internal__bridge.getU8a(ptr, len));
    };
    /** @internal */
    __wbg_randomFillSync_d5bd2d655fdf256a = (_idx, _ptr, _len) => {
        throw new Error('randomFillsync is not available');
        // getObject(idx).randomFillSync(getU8a(ptr, len));
    };
    /** @internal */
    __wbindgen_object_drop_ref = (idx) => {
        this.__internal__bridge.takeObject(idx);
    };
}
