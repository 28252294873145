export const runtime = {
    AssetConversionApi: [
        {
            methods: {
                get_reserves: {
                    description: 'Get pool reserves',
                    params: [
                        {
                            name: 'asset1',
                            type: 'XcmV3MultiLocation'
                        },
                        {
                            name: 'asset2',
                            type: 'XcmV3MultiLocation'
                        }
                    ],
                    type: 'Option<(Balance,Balance)>'
                },
                quote_price_exact_tokens_for_tokens: {
                    description: 'Quote price: exact tokens for tokens',
                    params: [
                        {
                            name: 'asset1',
                            type: 'XcmV3MultiLocation'
                        },
                        {
                            name: 'asset2',
                            type: 'XcmV3MultiLocation'
                        },
                        {
                            name: 'amount',
                            type: 'u128'
                        },
                        {
                            name: 'include_fee',
                            type: 'bool'
                        }
                    ],
                    type: 'Option<(Balance)>'
                },
                quote_price_tokens_for_exact_tokens: {
                    description: 'Quote price: tokens for exact tokens',
                    params: [
                        {
                            name: 'asset1',
                            type: 'XcmV3MultiLocation'
                        },
                        {
                            name: 'asset2',
                            type: 'XcmV3MultiLocation'
                        },
                        {
                            name: 'amount',
                            type: 'u128'
                        },
                        {
                            name: 'include_fee',
                            type: 'bool'
                        }
                    ],
                    type: 'Option<(Balance)>'
                }
            },
            version: 1
        }
    ]
};
