export default {
    rpc: {},
    types: {
        VestingInfo: {
            locked: 'Balance',
            perBlock: 'Balance',
            startingBlock: 'BlockNumber'
        }
    }
};
