"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWasm = exports.packageInfo = void 0;
const wasm_bridge_1 = require("@polkadot/wasm-bridge");
const wasm_crypto_wasm_1 = require("@polkadot/wasm-crypto-wasm");
var packageInfo_js_1 = require("./packageInfo.js");
Object.defineProperty(exports, "packageInfo", { enumerable: true, get: function () { return packageInfo_js_1.packageInfo; } });
/**
 * @name createWasm
 * @description
 * Creates an interface using only WASM
 */
exports.createWasm = (0, wasm_bridge_1.createWasmFn)('crypto', wasm_crypto_wasm_1.wasmBytes, null);
