/**
 * @name DoNotConstruct
 * @description
 * An unknown type that fails on construction with the type info
 */
export class DoNotConstruct {
    registry;
    createdAtHash;
    isStorageFallback;
    __internal__neverError;
    constructor(registry, typeName = 'DoNotConstruct') {
        this.registry = registry;
        this.__internal__neverError = new Error(`DoNotConstruct: Cannot construct unknown type ${typeName}`);
        throw this.__internal__neverError;
    }
    static with(typeName) {
        return class extends DoNotConstruct {
            constructor(registry) {
                super(registry, typeName);
            }
        };
    }
    /**
     * @description The length of the value when encoded as a Uint8Array
     */
    get encodedLength() {
        throw this.__internal__neverError;
    }
    /**
     * @description returns a hash of the contents
     */
    get hash() {
        throw this.__internal__neverError;
    }
    /**
     * @description Checks if the value is an empty value (always true)
     */
    get isEmpty() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    eq() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    inspect() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    toHex() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    toHuman() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    toJSON() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    toPrimitive() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    toRawType() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    toString() {
        throw this.__internal__neverError;
    }
    /**
     * @description Unimplemented
     */
    toU8a() {
        throw this.__internal__neverError;
    }
}
