import { isHex, isU8a, u8aToHex, u8aToU8a } from '@polkadot/util';
import { decodeAddress } from '@polkadot/util-crypto';
export class Pairs {
    __internal__map = {};
    add(pair) {
        this.__internal__map[decodeAddress(pair.address).toString()] = pair;
        return pair;
    }
    all() {
        return Object.values(this.__internal__map);
    }
    get(address) {
        const pair = this.__internal__map[decodeAddress(address).toString()];
        if (!pair) {
            throw new Error(`Unable to retrieve keypair '${isU8a(address) || isHex(address)
                ? u8aToHex(u8aToU8a(address))
                : address}'`);
        }
        return pair;
    }
    remove(address) {
        delete this.__internal__map[decodeAddress(address).toString()];
    }
}
