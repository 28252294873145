import { runtime } from './runtime.js';
export default {
    rpc: {},
    runtime,
    types: {
        RawAuraPreDigest: {
            slotNumber: 'u64'
        }
    }
};
