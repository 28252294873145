import { map, of } from 'rxjs';
import { memo } from '../util/index.js';
export function referendumIds(instanceId, api) {
    return memo(instanceId, () => api.query.democracy?.lowestUnbaked
        ? api.queryMulti([
            api.query.democracy.lowestUnbaked,
            api.query.democracy.referendumCount
        ]).pipe(map(([first, total]) => total.gt(first)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            ? [...Array(total.sub(first).toNumber())].map((_, i) => first.addn(i))
            : []))
        : of([]));
}
