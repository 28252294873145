import * as accounts from './accounts/index.js';
import * as alliance from './alliance/index.js';
import * as bagsList from './bagsList/index.js';
import * as balances from './balances/index.js';
import * as bounties from './bounties/index.js';
import * as chain from './chain/index.js';
import * as contracts from './contracts/index.js';
import * as council from './council/index.js';
import * as crowdloan from './crowdloan/index.js';
import * as democracy from './democracy/index.js';
import * as elections from './elections/index.js';
import * as imOnline from './imOnline/index.js';
import * as membership from './membership/index.js';
import * as parachains from './parachains/index.js';
import * as session from './session/index.js';
import * as society from './society/index.js';
import * as staking from './staking/index.js';
import * as technicalCommittee from './technicalCommittee/index.js';
import * as treasury from './treasury/index.js';
import * as tx from './tx/index.js';
export const derive = { accounts, alliance, bagsList, balances, bounties, chain, contracts, council, crowdloan, democracy, elections, imOnline, membership, parachains, session, society, staking, technicalCommittee, treasury, tx };
