const DEFAULT_CAPACITY = 128;
class LRUNode {
    key;
    next;
    prev;
    constructor(key) {
        this.key = key;
        this.next = this.prev = this;
    }
}
export class LRUCache {
    capacity;
    __internal__data = new Map();
    __internal__refs = new Map();
    __internal__length = 0;
    __internal__head;
    __internal__tail;
    constructor(capacity = DEFAULT_CAPACITY) {
        this.capacity = capacity;
        this.__internal__head = this.__internal__tail = new LRUNode('<empty>');
    }
    get length() {
        return this.__internal__length;
    }
    get lengthData() {
        return this.__internal__data.size;
    }
    get lengthRefs() {
        return this.__internal__refs.size;
    }
    entries() {
        const keys = this.keys();
        const count = keys.length;
        const entries = new Array(count);
        for (let i = 0; i < count; i++) {
            const key = keys[i];
            entries[i] = [key, this.__internal__data.get(key)];
        }
        return entries;
    }
    keys() {
        const keys = [];
        if (this.__internal__length) {
            let curr = this.__internal__head;
            while (curr !== this.__internal__tail) {
                keys.push(curr.key);
                curr = curr.next;
            }
            keys.push(curr.key);
        }
        return keys;
    }
    get(key) {
        const data = this.__internal__data.get(key);
        if (data) {
            this.__internal__toHead(key);
            return data;
        }
        return null;
    }
    set(key, value) {
        if (this.__internal__data.has(key)) {
            this.__internal__toHead(key);
        }
        else {
            const node = new LRUNode(key);
            this.__internal__refs.set(node.key, node);
            if (this.length === 0) {
                this.__internal__head = this.__internal__tail = node;
            }
            else {
                this.__internal__head.prev = node;
                node.next = this.__internal__head;
                this.__internal__head = node;
            }
            if (this.__internal__length === this.capacity) {
                this.__internal__data.delete(this.__internal__tail.key);
                this.__internal__refs.delete(this.__internal__tail.key);
                this.__internal__tail = this.__internal__tail.prev;
                this.__internal__tail.next = this.__internal__head;
            }
            else {
                this.__internal__length += 1;
            }
        }
        this.__internal__data.set(key, value);
    }
    __internal__toHead(key) {
        const ref = this.__internal__refs.get(key);
        if (ref && ref !== this.__internal__head) {
            ref.prev.next = ref.next;
            ref.next.prev = ref.prev;
            ref.next = this.__internal__head;
            this.__internal__head.prev = ref;
            this.__internal__head = ref;
        }
    }
}
