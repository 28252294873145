export const knownTestnet = {
    '': true, // this is the default non-network entry
    'cess-testnet': true,
    'dock-testnet': true,
    jupiter: true,
    'mathchain-testnet': true,
    p3dt: true,
    subspace_testnet: true,
    'zero-alphaville': true
};
