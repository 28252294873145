import { isString, objectSpread, u8aToHex, u8aToU8a } from '@polkadot/util';
import { Getters } from './Getters.js';
export class ApiBase extends Getters {
    /**
     * @description Create an instance of the class
     *
     * @param options Options object to create API instance or a Provider instance
     *
     * @example
     * <BR>
     *
     * ```javascript
     * import Api from '@polkadot/api/promise';
     *
     * const api = new Api().isReady();
     *
     * api.rpc.subscribeNewHeads((header) => {
     *   console.log(`new block #${header.number.toNumber()}`);
     * });
     * ```
     */
    constructor(options = {}, type, decorateMethod) {
        super(options, type, decorateMethod);
    }
    /**
     * @description Connect from the underlying provider, halting all network traffic
     */
    connect() {
        return this._rpcCore.connect();
    }
    /**
     * @description Disconnect from the underlying provider, halting all network traffic
     */
    disconnect() {
        this._unsubscribe();
        return this._rpcCore.disconnect();
    }
    /**
     * @description Set an external signer which will be used to sign extrinsic when account passed in is not KeyringPair
     */
    setSigner(signer) {
        this._rx.signer = signer;
    }
    /**
     * @description Signs a raw signer payload, string or Uint8Array
     */
    async sign(address, data, { signer } = {}) {
        if (isString(address)) {
            const _signer = signer || this._rx.signer;
            if (!_signer?.signRaw) {
                throw new Error('No signer exists with a signRaw interface. You possibly need to pass through an explicit keypair for the origin so it can be used for signing.');
            }
            return (await _signer.signRaw(objectSpread({ type: 'bytes' }, data, { address }))).signature;
        }
        return u8aToHex(address.sign(u8aToU8a(data.data)));
    }
}
