export const statemint = {
    ChargeAssetTxPayment: {
        extrinsic: {
            tip: 'Compact<Balance>',
            // eslint-disable-next-line sort-keys
            assetId: 'TAssetConversion'
        },
        payload: {}
    }
};
